<template>
  <div class="userInfo">
    <div class="userInfo-popup" v-if="popupShow">复制成功</div>
    <div class="userInfo-user flex">
      <div class="userInfo-user-img">
        <img
          :src="userInfo.headImg"
          alt=""
          style="width: 100%; height: 100%; border-radius: 50%"
        />
      </div>
      <div class="userInfo-user-text">
        <div class="name">{{ userInfo.name }}</div>
        <div
          class="flex items-center"
          style="margin-top: 8px; color: #fbdfb4; font-size: 20px"
        >
          <span>UID账号：{{ userInfo.userId }}</span>
          <img
            src="https://cdn.bubbleplan.cn/static/user/Maskgroup2x.png"
            alt=""
            sizes=""
            srcset=""
            class="userInfo-user-text-copyt"
            @click="copyTextButton(userInfo.userId, 'uid')"
          />
        </div>
        <div
          class="flex items-center"
          style="margin-top: 8px; color: #fbdfb4; font-size: 20px"
        >
          <span
            >区块链地址：{{
              strSubstr(userInfo.userWalletAddress, 4, 34)
            }}</span
          >
          <img
            src="https://cdn.bubbleplan.cn/static/user/Maskgroup2x.png"
            alt=""
            sizes=""
            srcset=""
            class="userInfo-user-text-copyt"
            @click="copyTextButton(userInfo.userWalletAddress, '区块链地址')"
          />
        </div>
      </div>
    </div>

    <div class="userInfo-name">
      <div class="title">用户名</div>
      <div class="userInfo-name-input">
        <el-input v-model="input" placeholder="请输入昵称"></el-input>
      </div>
      <div class="title">头像</div>
      <div class="userInfo-name-userImg flex">
        <img
          :src="item.img"
          v-for="(item, index) in imgList"
          :key="index"
          alt=""
          class="userInfo-name-userImg-i"
          :class="
            changeTheAvatarIndex == index ? 'changeTheAvatarClass-img1' : ''
          "
          @click="changeTheAvatarB(item, index)"
        />
      </div>
      <div class="userInfo-name-button">
        <el-button
          type="success"
          :disabled="isdisabled"
          @click="modifyingaUserName()"
          >保存</el-button
        >
      </div>
    </div>

    <el-dialog
      title="修改头像"
      :visible.sync="changeTheAvatarShow"
      width="30%"
      @close="close"
    >
      <div class="changeTheAvatarClass">
        <div class="flex">
          <div
            v-for="(item, index) in imgList"
            :key="index"
            class="changeTheAvatarClass-img"
            :class="
              changeTheAvatarIndex == index ? 'changeTheAvatarClass-img1' : ''
            "
            @click="changeTheAvatarB(item, index)"
          >
            <img :src="item.img" alt="" style="width: 100%; height: 100%" />
          </div>
        </div>

        <div
          class="changeTheAvatarClass-button"
          @click="changeTheAvatarButton()"
        >
          确认
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import index from "@xkeshi/vue-qrcode";

export default {
  props: {
    userInfo: Object,
  },
  data() {
    return {
      popupShow: false,
      input: "",
      isdisabled: true,
      changeTheAvatarShow: false,
      imgList: [
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/1.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/2.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/3.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/4.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/5.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/6.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/7.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/8.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/9.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/mine/10.png",
        },
      ],
      changeTheAvatarIndex: null,
      headImg: "",
    };
  },
  watch: {
    input(newValue) {
      if (newValue) {
        this.isdisabled = false;
      } else {
        this.isdisabled = true;
      }
    },
    changeTheAvatarIndex(newValue) {
      if (newValue == null) {
        this.isdisabled = true;
      } else {
        if (newValue >= 0) {
          this.isdisabled = false;
        } else {
          this.isdisabled = true;
        }
      }
    },
  },
  methods: {
    close() {
      this.changeTheAvatarIndex = null;
    },
    //换头像
    async changeTheAvatarButton(item, index) {
      const { data, code } = await this.$ajax.updateInfo({
        headImg: this.headImg,
      });
      if (code == 200) {
        this.headImg = "";
        this.changeTheAvatarShow = false;
        this.$emit("updateInfo");
        this.$emit("getUserInfo");
        this.close();
      }
    },
    changeTheAvatarB(item, index) {
      this.changeTheAvatarIndex = index;
      this.headImg = item.img;
    },

    changeTheAvatar() {
      this.changeTheAvatarShow = true;
    },
    //复制
    copyTextButton(text, name) {
      if (!text) {
        this.$message.error(`复制内容不能为空`);
        return;
      }
      text = text.toString();
      this.$copyText(text)
        .then(() => {
          this.popupShow = true;
          setTimeout(() => {
            this.popupShow = false;
          }, 1500);
          // this.$message.success(`${name}复制成功`);
        })
        .catch(() => {
          // this.$message.error(`${name}复制失败`);
        });
    },
    strSubstr(str, start, end) {
      if (str) {
        return (
          str.substr(0, start) + "****" + str.substr(end, str.split("").length)
        );
      }
    },
    //修改昵称
    async modifyingaUserName() {
      if (this.input.length > 7) {
        this.$message.error(`用户名仅限输入7位`);
        return;
      }

      // if (!this.input) {
      //   return;
      // }
      if (this.input == " ") {
        return;
      }
      if (this.input == "  ") {
        return;
      }
      // let s = /^\S+$/;
      // if (!s.test(this.input)) {
      //   return;
      // }

      const params = {};
      console.log(this.changeTheAvatarIndex);
      if (this.changeTheAvatarIndex != null) {
        params.headImg = this.imgList[this.changeTheAvatarIndex].img;
      }
      if (this.input) {
        params.name = this.input;
      }
      const { data, code } = await this.$ajax.updateInfo(params);
      if (code == 200) {
        this.headImg = "";
        this.input = "";
        this.changeTheAvatarIndex = null;
        this.$emit("updateInfo");
        this.$emit("getUserInfo");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.userInfo {
  // width: 892px;
  // height: 698px;
  // background: url("https://cdn.bubbleplan.cn/static/user/Group104472x.png")
  //   no-repeat;
  // background-size: 100% 100%;
  // border-radius: 20px;
  padding: 38px 68px;
  position: relative;
  .userInfo-popup {
    width: 750px;
    height: 52px;
    flex-shrink: 0;
    background: linear-gradient(
      90deg,
      #5a50cf00 0%,
      #8277ff 23%,
      #8a7fff 76%,
      #5a50cf00 99.5%
    );
    position: absolute;
    top: 0;
    left: 71px;
    margin: -17px;
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    font-family: "TsangerYuMo";
    font-weight: bold;
    line-height: 52px;
  }
  .userInfo-user-text-copyt:hover {
    cursor: pointer;
  }
  .userInfo-user-text-copyt {
    width: 19px;
    height: 23px;
    margin-left: 8px;
  }
  .userInfo-user {
    // padding: 35px 43px;
    width: 461px;
    height: 133px;
    background: url("https://cdn.bubbleplan.cn/static/user/Group103872x.png")
      no-repeat;
    background-size: 100% 100%;
    &-img {
      width: 122px;
      height: 122px;
      border-radius: 50%;
      margin-left: -23px;
    }
    // &-img:hover {
    //   cursor: pointer;
    // }

    &-text {
      margin-left: 20px;

      .name {
        color: #fef5de;
        font-size: 22px;
        text-align: left;
        text-shadow: -3px -3px 0 #533690, 3px -3px 0 #533690, -3px 3px 0 #533690,
          3px 3px 0 #533690;
        /* 模拟描边效果 */
        font-family: "TsangerYuMo";
        font-size: 34px;
        font-weight: bold;
        margin-top: -8px;
      }
    }
  }

  .userInfo-name {
    .changeTheAvatarClass-img1 {
      border: 4px solid #341030;
      border-radius: 50%;
    }
    width: 926px;
    height: 450px;
    background: url("https://cdn.bubbleplan.cn/static/user/Vector5472x.png")
      no-repeat;
    background-size: 100% 100%;
    margin-top: 35px;
    padding: 25px 89px;
    &-input {
      width: 100%;
      margin-top: 13px;
      margin-bottom: 30px;
    }
    &-userImg {
      margin-top: 12px;
      padding: 0 135px;
      flex-wrap: wrap;
      &-i:hover {
        cursor: pointer;
      }
      &-i {
        width: 79px;
        height: 79px;
        border-radius: 50%;
        margin-right: 16px;
        margin-bottom: 17px;
      }
    }
    .title {
      color: #fef5de;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
        2px 2px 0 #341030;
      /* 模拟描边效果 */
      font-family: "TsangerYuMo";
    }

    :deep(.el-input__inner) {
      height: 63px;
      background: url("https://cdn.bubbleplan.cn/static/user/Vector5642x.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 10px;
      border: none;
      font-size: 23px;
      color: #d9d2e4;
      text-align: center;
      font-family: "TsangerYuMo";
      font-weight: bold;
    }
    :deep(.el-input__inner)::placeholder {
      color: #d9d2e4;
      text-align: center;
      font-size: 23px;
      font-family: "TsangerYuMo";
      font-weight: bold;
    }

    .userInfo-name-button {
      width: 144px;
      height: 61px;
      margin: auto;
      margin-top: 17px;

      :deep(.el-button) {
        margin: auto;
        width: 144px;
        height: 61px;
        border-radius: 6.73px;
        color: #13212f;
        font-size: 22px;
      }

      :deep(.el-button--success.is-disabled) {
        width: 144px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/Frame96192x.png")
          no-repeat;
        background-size: 100% 100%;
        border: none;
        margin: auto;
        color: #3d2f52;
        font-size: 26px;
        font-family: "TsangerYuMo";
        font-weight: bold;
        text-shadow: -2px -2px 0 #b2abdf, 2px -2px 0 #b2abdf, -2px 2px 0 #b2abdf,
          2px 2px 0 #b2abdf;
        /* 模拟描边效果 */
      }

      :deep(.el-button--success) {
        width: 144px;
        height: 61px;
        background: url("https://cdn.bubbleplan.cn/static/user/Frame96182x.png")
          no-repeat;
        background-size: 100% 100%;
        border: none;
        margin: auto;
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-weight: bold;
        text-shadow: -2px -2px 0 #533690, 2px -2px 0 #533690, -2px 2px 0 #533690,
          2px 2px 0 #533690;
        /* 模拟描边效果 */
      }
    }
  }
  :deep(.el-dialog__header) {
    border-bottom: 0 !important;
  }
  :deep(.el-dialog__title) {
    color: #dfe8f6 !important;
    font-size: 23px !important;
  }
  :deep(.el-dialog) {
    width: auto !important;
    display: inline-block !important;
    margin-top: 15vh !important;
    margin-left: 65vh !important;
  }
  .changeTheAvatarClass {
    &-img {
      width: 109px;
      height: 109px;
      margin-right: 19px;
    }
    &-img:hover {
      cursor: pointer;
    }
    &-img1 {
      border-radius: 50%;
      border: 2px solid #daff00;
    }
    &-button {
      margin: 40px auto;
      width: 183.75px;
      height: 59px;
      flex-shrink: 0;
      border-radius: 6.73px;
      background: #daff00;
      color: #10061e;
      line-height: 59px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 0;
    }
    &-button:hover {
      cursor: pointer;
    }
  }
}
</style>